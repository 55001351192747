import React from "react";
import { noop } from "lodash";
import { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import { useColorTokens } from "@tvg/design-system";
import { type IconNames, Icon, Tag } from "@tvg/design-system/web";
import { isFdrProduct } from "@tvg/sh-utils/mobileUtils";
import {
  AccountLinkGroup,
  LinkText,
  AccountExternalLink,
  IconWrapper,
  LinkDeskWrapper
} from "./styled-components";

export interface Props {
  qaLabel: string;
  to: string;
  onClick: NullaryFn<void> | UnaryFn<Event, void>;
  linkText: string;
  highlightedText?: string;
  leftIcon?: IconNames;
  isExternalLink?: boolean;
  hideLeftIcon?: boolean;
  hideRightIcon?: boolean;
  onTouchStart?: NullaryFn<void> | UnaryFn<Event, void>;
  disabled?: boolean;
  renderLink?: boolean;
}

const AccountMenuLink = ({
  qaLabel,
  to,
  onClick,
  linkText,
  leftIcon,
  highlightedText,
  isExternalLink,
  hideLeftIcon,
  hideRightIcon,
  disabled,
  renderLink = false
}: Props) => {
  let AccountLinkComp: React.ElementType = isExternalLink
    ? AccountExternalLink
    : AccountLinkGroup;

  AccountLinkComp =
    isFdrProduct() || renderLink ? AccountLinkComp : LinkDeskWrapper;

  const iconColor = useColorTokens("content.subtle");
  const linkColor = useColorTokens("content.link");

  return (
    <AccountLinkComp
      onTouchStart={noop}
      data-qa-label={qaLabel}
      to={to}
      onClick={onClick}
      disabled={disabled}
    >
      {!hideLeftIcon && leftIcon && (
        <IconWrapper auto={false}>
          <Icon
            marginLeft="0px"
            marginRight="12px"
            name={leftIcon}
            lineColor={iconColor}
            size="m"
            qaLabel={`${qaLabel}-icon`}
          />
        </IconWrapper>
      )}
      <LinkText data-qa-label={`${qaLabel}-text`}>
        {linkText}
        {highlightedText && (
          <Tag
            size="small"
            label={highlightedText}
            variant="neutral"
            marginLeft="8px"
            hideIcon
          />
        )}
      </LinkText>
      {!hideRightIcon && (
        <IconWrapper auto={true}>
          <Icon
            name="chevronRight"
            lineColor={linkColor}
            size="s"
            qaLabel={`${qaLabel}-arrow`}
          />
        </IconWrapper>
      )}
    </AccountLinkComp>
  );
};

AccountMenuLink.defaultProps = {
  qaLabel: "account-nav-default",
  to: "/",
  onClick: noop,
  linkText: "Default",
  highlightedText: "",
  leftIcon: "",
  isExternalLink: false,
  hideLeftIcon: false,
  hideRightIcon: false,
  disabled: false
};

export default AccountMenuLink;
