import React from "react";
import formatSequential from "@tvg/formatter/numeric";
import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { useColorTokens } from "../../../../hooks";
import { Saddle } from "../../../Saddle";
import { Tag } from "../../../Tag";
import { Paragraph } from "../../../Typography";
import { Runner } from "../../types";
import { LABELS } from "../../constants";
import {
  RunnerItemContainer,
  Position,
  FavoriteTag,
  PayoutsContainer,
  PayoutValue
} from "./styled-components";

interface RunnerItemProps {
  raceTypeCode: RaceTypeCodeEnum;
  runner: Runner;
  isHighlight: boolean;
}

export const RunnerItem: React.FC<RunnerItemProps> = React.memo(
  ({ raceTypeCode, runner, isHighlight = false }) => {
    const colorTokens = useColorTokens();
    return (
      <RunnerItemContainer>
        <Position
          qaLabel={`position-${runner.position}`}
          color={colorTokens.content.subtle}
          fontSize="xs"
        >
          {formatSequential(runner.position)}
        </Position>
        <Saddle
          number={runner.saddlecloth}
          size="s"
          breed={
            raceTypeCode === RaceTypeCodeEnum.HARNESS
              ? "harness"
              : "thoroughbred"
          }
        />
        <Paragraph
          qaLabel={`runnerName-${runner.position}`}
          color={colorTokens.content.default}
          fontFamily={isHighlight ? "bold" : "regular"}
          fontSize="xs"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {runner.name}
        </Paragraph>
        {runner.isFavorite && (
          <FavoriteTag>
            <Tag
              size="small"
              variant="favorite"
              label={LABELS.FAVORITE}
              hideIcon
            />
          </FavoriteTag>
        )}
        <PayoutsContainer>
          {runner.win && (
            <PayoutValue qaLabel={`${runner.name}-win`}>
              {runner.win}
            </PayoutValue>
          )}
          {runner.place && (
            <PayoutValue qaLabel={`${runner.name}-place`}>
              {runner.place}
            </PayoutValue>
          )}
          {runner.show && (
            <PayoutValue qaLabel={`${runner.name}-show`}>
              {runner.show}
            </PayoutValue>
          )}
        </PayoutsContainer>
      </RunnerItemContainer>
    );
  }
);
