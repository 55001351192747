import { isXSell } from "@tvg/sh-utils/mobileUtils";
import {
  onOpenComplianceModal,
  onTriggerAccountSummary
} from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import type { MouseEvent } from "react";
import type { NavigateFunction } from "react-router";
import { get } from "lodash";
import type { IconNames } from "@tvg/design-system/web/components/Icon/types";
import {
  getAuthTokenCookie,
  isSessionExpired
} from "@tvg/sh-utils/sessionUtils";
import type { ParseItemArgs } from "./types";
import type { FooterRoute, NavigationItem } from "../../types";
import { onLogin } from "../../utils";

const fallbackIcon: IconNames = "warning";
const iconsMap: Record<string, IconNames> = {
  home: "horse",
  tracks: "search",
  bets: "bets",
  account: "user",
  modeBalanceHidden: "eyeHide",
  promos: "promo",
  more: "hamburger"
};

export const getIconByName = (
  item: FooterRoute,
  isLogged: boolean,
  noLoginRequired: boolean
): IconNames => {
  const elementKey = item.key.toLowerCase();

  if (elementKey === "account" && isLogged && !item.showBalance) {
    return get(iconsMap, "modeBalanceHidden", fallbackIcon);
  }
  if (elementKey === "account" && noLoginRequired && !isXSell() && !isLogged) {
    return get(iconsMap, "more", fallbackIcon);
  }

  return get(iconsMap, elementKey, fallbackIcon);
};

export const parseAccountItem = ({
  navigate,
  item,
  to,
  isLogged,
  isNoLoginRequired,
  currentPath = "",
  showBalance
}: ParseItemArgs): NavigationItem => ({
  ...item,
  name: isNoLoginRequired && !isLogged && !isXSell() ? "More" : item.name,
  iconName: getIconByName(item, isLogged, isNoLoginRequired),
  to: (isNoLoginRequired || isLogged) && !isXSell() ? to : currentPath,
  showBalance: !!(isLogged && showBalance),
  onClick: () => {
    const hasSessionExpired = isSessionExpired(getAuthTokenCookie());
    if (isXSell() && (!isLogged || hasSessionExpired)) {
      onLogin();
      return;
    }

    if (isXSell() || isLogged) {
      onTriggerAccountSummary(to.toString());
    } else if (!isNoLoginRequired) {
      // This onclick dispatches the Account item event, which is already being triggered on onTriggerAccountSummary
      item.onClick();
      onLogin({
        callback: () => {
          if (navigate) navigate(to);
        }
      });
    }
  }
});

export const parseBetsItem = ({
  isLogged,
  item,
  navigate,
  isAccountCompliant,
  to,
  isNoLoginRequired,
  counter
}: ParseItemArgs): NavigationItem => ({
  ...item,
  to,
  iconName: getIconByName(item, isLogged, isNoLoginRequired),
  counter,
  onClick: (event: MouseEvent<HTMLAnchorElement>) => {
    if (!isLogged) {
      onLogin({
        callback: () => {
          if (navigate) navigate(to);
        }
      });
      event.preventDefault();
    } else if (isLogged && !isAccountCompliant) {
      onOpenComplianceModal();
      event.preventDefault();
    }
    item.onClick();
  }
});

export const parseGenericItem = ({
  isLogged,
  item,
  to,
  isNoLoginRequired
}: ParseItemArgs): NavigationItem => ({
  ...item,
  to,
  iconName: getIconByName(item, isLogged, isNoLoginRequired)
});

export const parseRoutesToNavItems = (
  routes: FooterRoute[],
  isPromosEnabled: boolean,
  currentPath: string,
  navigate: NavigateFunction,
  isLogged: boolean,
  isNoLoginRequired: boolean,
  showBalance: boolean,
  isAccountCompliant: boolean,
  counter: number
) => {
  const items = [...routes];

  // remove promos when disabled
  if (!isPromosEnabled) {
    const indexOf = routes.findIndex((value) => value.key === "promos");
    items.splice(indexOf, 1);
  }

  return items.map<NavigationItem>((item) => {
    const to =
      item.href.indexOf("#") === 0 ? `${currentPath}${item.href}` : item.href;

    if (item.key === "account") {
      return parseAccountItem({
        navigate,
        item,
        to,
        isLogged,
        isNoLoginRequired,
        currentPath,
        showBalance
      });
    }

    if (item.key === "bets") {
      return parseBetsItem({
        isLogged,
        item,
        navigate,
        isAccountCompliant,
        to,
        isNoLoginRequired,
        counter
      });
    }

    return parseGenericItem({ isLogged, item, to, isNoLoginRequired });
  });
};
