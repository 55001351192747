import React from "react";
import { useColorTokens } from "../../../../hooks";
import { LoadingContainer, SVG } from "./styled-components";

interface Props {
  qaLabel: string;
}

export const LoadingMask = ({ qaLabel }: Props) => {
  const greyBgColor = useColorTokens("content.loadingDefault");
  return (
    <LoadingContainer>
      <SVG width="275" height="200" fill={greyBgColor} data-qa-label={qaLabel}>
        <rect x="8" y="10" width="100" height="10" fill="#0A0A0A" rx="1" />
        <rect x="128" y="10" width="59" height="10" fill="#0A0A0A" rx="1" />
        <rect x="8" y="40" width="259" height="16" fill="#0A0A0A" rx="1" />
        <rect x="8" y="80" width="259" height="16" fill="#0A0A0A" rx="1" />
        <rect x="8" y="120" width="259" height="16" fill="#0A0A0A" rx="1" />
        <rect x="8" y="160" width="259" height="16" fill="#0A0A0A" rx="1" />
        <rect x="8" y="200" width="259" height="16" fill="#0A0A0A" rx="1" />
      </SVG>
    </LoadingContainer>
  );
};
