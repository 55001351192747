import { IconSizeVariants } from "../../theme";
import { useColorTokens } from "../../hooks";

export interface Variants {
  [key: string]: {
    mainCircleBackground: string;
    badgeIcon: {
      lineColor: string;
      backgroundColor: string;
      size: IconSizeVariants;
    };
    mainIcon: {
      lineColor: string;
      backgroundColor: string;
      size: IconSizeVariants;
    };
  };
}

export const iconWithBackgroundVariations: Variants = {
  info: {
    mainCircleBackground: "--fd-colors-system-info-background-subtle",
    badgeIcon: {
      lineColor: "--fd-colors-system-info-content-accent",
      backgroundColor: "--fd-colors-system-info-background-subtle",
      size: "s"
    },
    mainIcon: {
      lineColor: "--fd-colors-system-info-content-accent",
      backgroundColor: "--fd-colors-system-info-background-subtle",
      size: "l"
    }
  },
  positive: {
    mainCircleBackground: "--fd-colors-system-positive-background-subtle",
    badgeIcon: {
      lineColor: "--fd-colors-system-positive-content-accent",
      backgroundColor: "--fd-colors-system-positive-background-subtle",
      size: "s"
    },
    mainIcon: {
      lineColor: "--fd-colors-system-positive-content-accent",
      backgroundColor: "--fd-colors-system-positive-background-subtle",
      size: "l"
    }
  },
  warning: {
    mainCircleBackground: "--fd-colors-system-warning-background-subtle",
    badgeIcon: {
      lineColor: "--fd-colors-system-warning-content-accent",
      backgroundColor: "--fd-colors-system-warning-background-subtle",
      size: "s"
    },
    mainIcon: {
      lineColor: "--fd-colors-system-warning-content-accent",
      backgroundColor: "--fd-colors-system-warning-background-subtle",
      size: "l"
    }
  },
  important: {
    mainCircleBackground: "--fd-colors-system-important-background-subtle",
    badgeIcon: {
      lineColor: "--fd-colors-system-important-content-accent",
      backgroundColor: "--fd-colors-system-important-background-subtle",
      size: "s"
    },
    mainIcon: {
      lineColor: "--fd-colors-system-important-content-accent",
      backgroundColor: "--fd-colors-system-important-background-subtle",
      size: "l"
    }
  },
  alert: {
    mainCircleBackground: "--fd-colors-system-alert-background-subtle",
    badgeIcon: {
      lineColor: "--fd-colors-system-alert-content-accent",
      backgroundColor: "--fd-colors-system-alert-background-subtle",
      size: "s"
    },
    mainIcon: {
      lineColor: "--fd-colors-system-alert-content-accent",
      backgroundColor: "--fd-colors-system-alert-background-subtle",
      size: "l"
    }
  }
};

const filterColor = (color: string | undefined) =>
  color && (color.includes("#") || color.includes("rgba"))
    ? color
    : useColorTokens(color);

export const checkColor = (color: string) => {
  if (color.startsWith("var")) {
    return color;
  }

  return color.startsWith("--") ? `var(${color})` : filterColor(color);
};
