import React, { createContext } from "react";
import { type PebbleGroupContextProps, type PebbleGroupProps } from "./types";
import { StyledViewContainer } from "./styled-components";

const initialState = { selectedValue: "", onChange: () => {} };

export const GroupContext =
  createContext<PebbleGroupContextProps>(initialState);

export const PebbleGroup = ({
  children,
  selectedValue,
  onChange,
  qaLabel = "pebble-group",
  ...rest
}: PebbleGroupProps) => (
  <StyledViewContainer {...rest} data-qa-label={qaLabel}>
    <GroupContext.Provider value={{ selectedValue, onChange }}>
      {children}
    </GroupContext.Provider>
  </StyledViewContainer>
);
