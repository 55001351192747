import { some } from "lodash";

import mediator from "@tvg/mediator";
import { openExternalLink } from "@tvg/sh-utils/mobileUtils";
import { TutorialListFromService } from "@tvg/ts-types/OnBoarding";

export const moreLinksGtm = (
  url: string,
  linkName: string,
  menu?: string
): void => {
  mediator.base.dispatch({
    type: "MORE_NAVIGATION",
    payload: {
      linkName,
      url,
      menu
    }
  });
};

export const accountLinksGtm = (
  url: string,
  linkName: string,
  menu?: string
): void => {
  mediator.base.dispatch({
    type: "ACCOUNT_NAVIGATION",
    payload: {
      linkName,
      url,
      menu
    }
  });
};

export const openContentMoreGtm = (itemOpened: string): void => {
  mediator.base.dispatch({
    type: "MORE_OPEN_CONTENT",
    payload: {
      itemOpened
    }
  });
};

export const onExternalLinkCallback = (url: string, event: Event): void => {
  event.preventDefault();
  event.stopPropagation();
  openExternalLink(url);
};

export const hasNewLink = (tutorialsList: TutorialListFromService[]): boolean =>
  some(
    tutorialsList,
    (tutorialsListItem: TutorialListFromService): boolean =>
      tutorialsListItem.isNew
  );

export const isAndroid = (product: string) =>
  product === "fdrandroid" || product === "fdrandroidgps";

export const supportOpenGtm = (): void => {
  mediator.base.dispatch({
    type: "SUPPORT_MODAL_TOGGLE",
    payload: {
      ToggleModal: "open"
    }
  });
};

export const navigateTutorialsGtm = (destinationUrl: string): void => {
  mediator.base.dispatch({
    type: "FDR_TUTORIALS:NAVIGATE",
    payload: {
      destinationUrl,
      isFromQuickLinks: false
    }
  });
};

export const clickTransactionBeforeHistory = (): void => {
  mediator.base.dispatch({
    type: "NAVIGATION:MENU_CLICK",
    payload: {
      action: "Transaction Before Opened",
      module: "Account Panel"
    }
  });
};
