import React, { useContext, useState, useEffect } from "react";
import type { PebbleProps } from "./types";
import { PebbleGroup, GroupContext } from "./PebbleGroup";
import {
  TagContainer,
  Wrapper,
  Container,
  IconCircle
} from "./styled-components";
import { Paragraph } from "../Typography";
import { Icon } from "../Icon";
import { Tag } from "../Tag";

const Pebble = ({
  iconName,
  title,
  description,
  tag,
  isSelected = false,
  qaLabel,
  value,
  ...rest
}: PebbleProps) => {
  const { selectedValue, onChange = () => {} } = useContext(GroupContext);
  const [selected, setSelected] = useState(false);

  const onClick = () => {
    onChange(value);
  };

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  useEffect(() => {
    setSelected(isSelected || selectedValue === value);
  }, [selectedValue]);

  const RenderTag = () =>
    tag ? (
      <TagContainer>
        {typeof tag === "string" ? (
          <Tag label={tag} variant="alert" size="small" />
        ) : (
          tag
        )}
      </TagContainer>
    ) : (
      <></>
    );

  return (
    <Wrapper data-qa-label={qaLabel} onClick={() => onClick()} {...rest}>
      <Container data-active={selected}>
        <RenderTag />
        <IconCircle data-active={selected}>
          <Icon
            name={iconName}
            size="m"
            lineColor="--fd-colors-system-info-content-accent"
            color="--fd-colors-content-strong"
            backgroundColor={
              selected ? "--fd-colors-system-info-background-subtle" : ""
            }
          />
        </IconCircle>
        <Paragraph
          color="--fd-colors-content-default"
          fontWeight={500}
          marginTop={0}
          marginBottom={4}
          fontSize="14px"
          lineHeight="125%"
          fontFamily="medium"
        >
          {title}
        </Paragraph>
        {description && (
          <Paragraph
            fontFamily="regular"
            fontWeight={400}
            color="--fd-colors-content-subtle"
            margin={0}
            fontSize="14px"
            lineHeight="125%"
          >
            {description}
          </Paragraph>
        )}
      </Container>
    </Wrapper>
  );
};

Pebble.Group = PebbleGroup;
export { type PebbleProps, Pebble };
