import styled from "styled-components";
import { fontProxima } from "@tvg/atomic-ui/_static/Typography";

export const SessionInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  font-size: 10px;
  font-family: ${fontProxima};
  font-weight: normal;
`;

export const SessionDescription = styled.span`
  font-weight: bold;
`;
