export const globalWalletMenuDataDefault = {
  balance: {
    label: "Account Overview",
    link: "/account",
    isExternal: true
  },
  transactionsHistory: {
    label: "Transactions History",
    link: "/account",
    isExternal: false
  },
  transactionHistoryAW: {
    label: "Transactions after ",
    link: "/account",
    isExternal: false
  },
  taxCenter: {
    label: "Tax Center",
    link: "/account",
    isExternal: true
  },
  preferences: {
    label: "Preferences",
    link: "/account",
    isExternal: true
  },
  activityStatement: {
    label: "Activity Statement",
    link: "/account",
    isExternal: true
  },
  mySpend: {
    label: "My Spend",
    link: "/account",
    isExternal: true
  },
  settings: {
    label: "Account Settings",
    link: "/account",
    isExternal: true
  },
  derbyBetting: {
    label: "2023 Preakness Derby Betting Guide",
    link: "/",
    isExternal: true,
    url: "https://www.fanduel.com/preakness-stakes-betting"
  },
  talentPicks: {
    label: "FDTV Picks",
    link: "/handicapping/talent-picks",
    isExternal: false
  },
  watchFDTV: {
    label: "Watch FDTV",
    isExternal: false,
    link: "/live"
  },
  trackInformationList: {
    label: "Tracks Information List",
    isExternal: false,
    link: "/tracks/all-info"
  },
  faq: {
    label: "FanDuel FAQ",
    link: "/account",
    isExternal: true,
    url: "https://www.fanduel.com/racing-privacy"
  },
  tutorials: {
    label: "Tutorials",
    link: "/tutorials",
    isExternal: false
  },
  privacyPolicy: {
    label: "Privacy Policy",
    link: "/account",
    url: "https://www.fanduel.com/racing-privacy",
    isExternal: true
  },
  californiaPolicy: {
    label: "California Privacy Policy",
    link: "/account",
    isExternal: true,
    url: "https://www.fanduel.com/racing-privacy#Link13"
  },
  doNotSell: {
    label: "Your Privacy Choices",
    link: "/",
    isExternal: true,
    url: "https://privacy.fanduel.com/dont_sell"
  },
  accessibility: {
    label: "Accessibility",
    link: "#accessibility",
    isExternal: false
  },
  termsAndConidtions: {
    label: "Terms and Conditions",
    isExternal: true,
    link: "/account",
    url: "https://account.racing.qa.fndl.dev/terms"
  },
  feedback: {
    label: "Send Feedback",
    link: "mailto:support@tvg.com"
  },
  responsibleGaming: {
    label: "Responsible Gaming",
    link: "/account",
    isExternal: false
  },
  support: {
    label: "Support",
    url: "/account#support",
    isExternal: false
  }
};

export const playableBalance = {
  title: "Playable balance",
  description: "These are all the funds you can play in Racing.",
  sections: [
    {
      title: "Shared deposits",
      description:
        "You can use deposits made to Sportsbook, Casino, and Racing to play. Deposits made to Fantasy and Faceoff appear in non-playable balances."
    },
    {
      title: "Bonuses",
      description:
        "You can use bonuses from Racing only. All other bonuses appear in non-playable balances."
    },
    {
      title: "Shared winnings",
      description: "You can use any of your winnings across FanDuel."
    }
  ]
};
